import React, { useEffect, useState, useRef } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import ProgressiveImage from 'react-progressive-image-loading';
import { useScrollState } from 'scrollmonitor-hooks';
const PicHolder = (props) => {
  const { item, index } = props;
  const ref = useRef(null);
  const scrollState = useScrollState(ref);

  let cn = 0;
  let className = 'picHolder';
  if (scrollState.isInViewport) {
    className = 'picHolder show';
  } else {
    className = 'picHolder';
  }
  if (scrollState.isAboveViewport) {
    className = 'picHolder show';
  }

  return (
    <div ref={ref} key={'pic' + index} className={className} index={index}>
      <ProgressiveImage
        className="picture"
        preview={item.low}
        src={item.large}
        transitionTime={500}
        transitionFunction="ease"
        render={(src, style) => <img src={src} />}
        // width={'100%'}
        // style={{ height: 500 }}
        // style={className.indexOf('show') > -1 ? { opacity: 1 } : { opacity: 0 }}
        // effect="black-and-white"
        // src={className.indexOf('show') > -1 ? item.large : item.low}
      />
    </div>
  );
};

export default PicHolder;
