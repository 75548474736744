const pics = [
  {
    large: require('../pics_o/pic_01.jpg'),
    url: require('./pic_01.jpg'),
    low: require('../picslow/pic_01.jpeg'),
    w: 1615,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_02.jpg'),
    url: require('./pic_02.jpg'),
    low: require('../picslow/pic_02.jpeg'),
    w: 1599,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_03.jpg'),
    url: require('./pic_03.jpg'),
    low: require('../picslow/pic_03.jpeg'),
    w: 1600,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_04.jpg'),
    url: require('./pic_04.jpg'),
    low: require('../picslow/pic_04.jpeg'),
    w: 1615,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_05.jpg'),
    url: require('./pic_05.jpg'),
    low: require('../picslow/pic_05.jpeg'),
    w: 1615,
    h: 1081,
  },
  {
    large: require('../pics_o/pic_06.jpg'),
    url: require('./pic_06.jpg'),
    low: require('../picslow/pic_06.jpeg'),
    w: 1081,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_07.jpg'),
    url: require('./pic_07.jpg'),
    low: require('../picslow/pic_07.jpeg'),
    w: 1080,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_08.jpg'),
    url: require('./pic_08.jpg'),
    low: require('../picslow/pic_08.jpeg'),
    w: 1449,
    h: 968,
  },
  {
    large: require('../pics_o/pic_09.jpg'),
    url: require('./pic_09.jpg'),
    low: require('../picslow/pic_09.jpeg'),
    w: 1080,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_10.jpg'),
    url: require('./pic_10.jpg'),
    low: require('../picslow/pic_10.jpeg'),
    w: 1601,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_11.jpg'),
    url: require('./pic_11.jpg'),
    low: require('../picslow/pic_11.jpeg'),
    w: 1081,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_12.jpg'),
    url: require('./pic_12.jpg'),
    low: require('../picslow/pic_12.jpeg'),
    w: 1080,
    h: 1524,
  },
  {
    large: require('../pics_o/pic_13.jpg'),
    url: require('./pic_13.jpg'),
    low: require('../picslow/pic_13.jpeg'),
    w: 1080,
    h: 1507,
  },
  {
    large: require('../pics_o/pic_14.jpg'),
    url: require('./pic_14.jpg'),
    low: require('../picslow/pic_14.jpeg'),
    w: 1080,
    h: 1524,
  },
  {
    large: require('../pics_o/pic_15.jpg'),
    url: require('./pic_15.jpg'),
    low: require('../picslow/pic_15.jpeg'),
    w: 1081,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_16.jpg'),
    url: require('./pic_16.jpg'),
    low: require('../picslow/pic_16.jpeg'),
    w: 1589,
    h: 1100,
  },
  {
    large: require('../pics_o/pic_17.jpg'),
    url: require('./pic_17.jpg'),
    low: require('../picslow/pic_17.jpeg'),
    w: 1599,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_18.jpg'),
    url: require('./pic_18.jpg'),
    low: require('../picslow/pic_18.jpeg'),
    w: 1080,
    h: 1524,
  },
  {
    large: require('../pics_o/pic_19.jpg'),
    url: require('./pic_19.jpg'),
    low: require('../picslow/pic_19.jpeg'),
    w: 1081,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_20.jpg'),
    url: require('./pic_20.jpg'),
    low: require('../picslow/pic_20.jpeg'),
    w: 1081,
    h: 1080,
  },
  {
    large: require('../pics_o/pic_21.jpg'),
    url: require('./pic_21.jpg'),
    low: require('../picslow/pic_21.jpeg'),
    w: 1081,
    h: 1080,
  },
];

export default pics;
