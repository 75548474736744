const pics = [
  { url: require('./pic_01.webp'), low: require('../picslow/pic_01.jpeg') },
  { url: require('./pic_02.webp'), low: require('../picslow/pic_02.jpeg') },
  { url: require('./pic_03.webp'), low: require('../picslow/pic_03.jpeg') },
  { url: require('./pic_04.webp'), low: require('../picslow/pic_04.jpeg') },
  { url: require('./pic_05.webp'), low: require('../picslow/pic_05.jpeg') },
  { url: require('./pic_06.webp'), low: require('../picslow/pic_06.jpeg') },
  { url: require('./pic_07.webp'), low: require('../picslow/pic_07.jpeg') },
  { url: require('./pic_08.webp'), low: require('../picslow/pic_08.jpeg') },
  { url: require('./pic_09.webp'), low: require('../picslow/pic_09.jpeg') },
  { url: require('./pic_10.webp'), low: require('../picslow/pic_10.jpeg') },
  { url: require('./pic_11.webp'), low: require('../picslow/pic_11.jpeg') },
  { url: require('./pic_12.webp'), low: require('../picslow/pic_12.jpeg') },
  { url: require('./pic_13.webp'), low: require('../picslow/pic_13.jpeg') },
  { url: require('./pic_14.webp'), low: require('../picslow/pic_14.jpeg') },
  { url: require('./pic_15.webp'), low: require('../picslow/pic_15.jpeg') },
  { url: require('./pic_16.webp'), low: require('../picslow/pic_16.jpeg') },
  { url: require('./pic_17.webp'), low: require('../picslow/pic_17.jpeg') },
  { url: require('./pic_18.webp'), low: require('../picslow/pic_18.jpeg') },
  { url: require('./pic_19.webp'), low: require('../picslow/pic_19.jpeg') },
  { url: require('./pic_20.webp'), low: require('../picslow/pic_20.jpeg') },
  { url: require('./pic_21.webp'), low: require('../picslow/pic_21.jpeg') },
];

export default pics;
