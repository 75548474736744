import React, { useState, useEffect, useRef, createRef } from 'react';
import pics from './assets/pics/images';
import pics2 from './assets/pics_o/images';
import logo from './assets/logo.svg';
import { isMobile } from 'react-device-detect';
import { withCookies, Cookies } from 'react-cookie';
import { DateTime } from 'luxon';
import { map } from 'lodash';
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';
import TagManager from 'react-gtm-module';
import { Fade } from 'react-awesome-reveal';
import PicHolder from './PicHolder';
import Tracking from './Tracking';
import PicMag from './PicMag';

gsap.registerPlugin(Draggable);

const App = (props) => {
  const { cookies } = props;
  const holder = useRef(null);
  const [init, setInit] = useState(false);
  const [allRefs, setAllRefs] = useState(false);
  const picRefs = useRef(pics.map(() => createRef()));
  const [isAnim, setIsAnim] = useState(false);
  const [isMoveable, setIsMoveable] = useState(false);
  const active = useRef(null);
  const [showMag, setShowMag] = useState(false);
  const [selected, setSelected] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [cookieInfo, setCookieInfo] = useState(1);
  const [fixed, setFixed] = useState(false);
  const aPic = useRef(false);

  const initTracker = () => {
    const tagManagerArgs = {
      gtmId: 'GTM-PTXPXGR',
      dataLayer: {
        userProject: 'samtalsrapporten',
      },
    };
    TagManager.initialize(tagManagerArgs);
  };

  const handleScroll = () => {
    if (isMobile) {
      let scrollPosition = window.pageYOffset;
      if (scrollPosition === 0) {
        setFixed(false);
      } else {
        setFixed(true);
      }
    }
  };
  useEffect(() => {
    let rconcent = cookies.get('rconcent');
    if (!rconcent) {
      setShowCookie(true);
      let all = cookies.getAll();
      map(all, (value, key) => {
        cookies.remove(key, { path: '/', domain: 'samtalsrapporten.se' });
        cookies.remove(key, { path: '/', domain: '13.50.228.67' });
        cookies.remove(key, { path: '/', domain: 'localhost' });
      });
    } else {
      if (parseInt(rconcent, 10) === 1) {
        initTracker();
        Tracking.page('samtalsrapporten_' + (isMobile ? 'mobile' : 'desktop'));
        setShowCookie(false);
      } else {
        let all = cookies.getAll();
        map(all, (value, key) => {
          if (key !== 'rconcent') {
            cookies.remove(key, { path: '/', domain: 'samtalsrapporten.se' });
            cookies.remove(key, { path: '/', domain: '13.50.228.67' });
            cookies.remove(key, { path: '/', domain: 'localhost' });
          }
        });
        setShowCookie(false);
      }
    }
    if (isMobile) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (isMobile) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  const setCookie = (val) => {
    let d = DateTime.now().plus({ year: 1 });
    cookies.set('rconcent', val, {
      path: '/',
      expires: d.toJSDate(),
    });
    if (val === 1) {
      initTracker();
      setShowCookie(false);
      Tracking.page('samtalsrapporten_' + (isMobile ? 'mobile' : 'desktop'));
    } else if (val === 2) {
      setShowCookie(false);
    }
  };
  const openItem = (item) => {
    let x = gsap.getProperty(item.current, 'x');
    let y = gsap.getProperty(item.current, 'y');
    let r = gsap.getProperty(item.current, 'rotation');
    let s = gsap.getProperty(item.current, 'scale');
    let w = gsap.getProperty(item.current, 'width');
    let h = gsap.getProperty(item.current, 'height');

    item.current.lastX = x;
    item.current.lastY = y;
    item.current.lastR = r;
    item.current.lastS = s;
    item.current.lastW = w;
    item.current.lastH = h;
    active.current = item.current;
    let cw = item.current.clientWidth;
    let ch = item.current.clientHeight;
    let checkW = cw / (holder.current.clientWidth + 40);
    let checkH = ch / (holder.current.clientHeight + 40);

    let cwp = 0.9;
    let chp = 0.9;
    if (checkW > 1) {
      cwp = (holder.current.clientWidth - 40) / cw;
    }
    if (checkH > 1) {
      chp = (holder.current.clientHeight - 40) / ch;
    }
    let p = cwp;
    if (p > chp) {
      p = chp;
    }

    if (p > 1) {
      p = 0.8;
    }
    Draggable.zIndex = Draggable.zIndex + 1;
    let ind = parseInt(gsap.getProperty(item.current, 'index'), 10);

    setSelected(ind);
    gsap.set(item.current, { zIndex: 1000 });

    gsap.to(item.current, 0.5, {
      scale: 1,
      width: cw * p,
      height: ch * p,
      x: (holder.current.clientWidth - cw * p) * 0.5,
      y: (holder.current.clientHeight - ch * p) * 0.5,
      rotation: 0,
      zIndex: Draggable.zIndex,
      onComplete: () => {
        setIsAnim(false);
      },
    });
  };
  useEffect(() => {
    if (!selected) {
      aPic.current = null;
      setShowMag(false);
      gsap.to('.arrow', 0.3, { autoAlpha: 0 });
    }
  }, [selected]);

  useEffect(() => {
    if (holder.current && !init && allRefs) {
      setInit(true);
      if (!isMobile) {
        setTimeout(() => {
          picRefs.current.forEach((item, index) => {
            let blur = 50;
            gsap.to(item.current, 1, { delay: index * 0.1, autoAlpha: 1 });

            gsap.set(item.current, {
              scale: 0.3,
            });
            let x = gsap.utils.random(
              0,
              holder.current.clientWidth -
                item.current.getBoundingClientRect().width
            );
            let y = gsap.utils.random(
              80,
              holder.current.clientHeight * 0.9 -
                item.current.getBoundingClientRect().height
            );
            let rotation = gsap.utils.random(-5, 5);
            if (index === pics.length - 1 || index === pics.length - 2) {
              blur = 0;
              rotation = 0;
              if (index === pics.length - 1) {
                if (holder.current.clientWidth > 890) {
                  x =
                    holder.current.clientWidth * 0.5 -
                    item.current.getBoundingClientRect().width;
                }
                y =
                  holder.current.clientHeight * 0.5 -
                  item.current.getBoundingClientRect().height;
              }
              if (index === pics.length - 2) {
                if (holder.current.clientWidth > 890) {
                  x =
                    holder.current.clientWidth * 0.5 +
                    item.current.getBoundingClientRect().width * 0.2;
                }
                y =
                  holder.current.clientHeight * 0.6 -
                  item.current.getBoundingClientRect().height;
              }
            }
            let w = gsap.getProperty(item.current, 'width');
            let h = gsap.getProperty(item.current, 'height');
            item.current.lastX = x;
            item.current.lastY = y;
            item.current.lastR = rotation;
            item.current.lastS = 0.3;
            item.current.lastW = w;
            item.current.lastH = h;
            gsap.set(item.current, {
              x,
              y,
              rotation,
              webkitFilter: 'blur(' + blur + 'px)',
            });
            gsap.to(item.current, 1, {
              webkitFilter: 'blur(0px)',
              delay: 5,
            });
          });

          Draggable.zIndex = 100;
          Draggable.create('.picHolder', {
            bounds: holder.current,
            type: 'x,y',

            onRelease: (e) => {
              if (active.current !== null) {
                gsap.to(active.current, 0.5, {
                  scale: 0.3,
                  rotation: active.current.lastR,
                });
              }

              setSelected(false);
              active.current = null;
            },
            onClick: (e) => {
              let s = gsap.getProperty(e.target, 'scale');

              if (s === 0.3) {
                picRefs.current.forEach((item, index) => {
                  if (item.current === e.target) {
                    gsap.to('.arrow', 1, { autoAlpha: 1 });
                    openItem(item);
                  } else {
                    let s = gsap.getProperty(item.current, 'scale');
                    if (s > 0.3) {
                      gsap.to(item.current, 0.5, {
                        scale: 0.3,
                        width: item.current.lastW,
                        height: item.current.lastH,
                        x: item.current.lastX,
                        y: item.current.lastY,
                        rotation: item.current.lastR,
                      });
                    }
                  }
                });
              } else {
                picRefs.current.forEach((item, index) => {
                  if (
                    item.current === e.target.parentNode ||
                    item.current === e.target
                  ) {
                    gsap.to(item.current, 0.5, {
                      x: item.current.lastX,
                      y: item.current.lastY,
                      scale: 0.3,
                      rotation: item.current.lastR,
                      width: item.current.lastW,
                      height: item.current.lastH,
                    });
                  }
                });
              }
            },
            onDragStart: (e) => {
              let x = gsap.getProperty(e.target, 'x');
              let y = gsap.getProperty(e.target, 'y');
              let r = gsap.getProperty(e.target, 'rotation');
              e.target.lastX = x;
              e.target.lastY = y;
              e.target.lastR = r;
              active.current = e.target;
            },
            onDrag: function(e) {
              if (active.current !== null) {
                this.update({ applyBounds: true });
                gsap.to(active.current, 0.5, { scale: 0.5, rotation: 0 });
              }
            },
          });
        }, 1000);
      }
    }
  }, [holder, init, allRefs]);

  useEffect(() => {
    let done = true;
    picRefs.current.forEach((ref) => {
      if (!ref || ref === null) {
        done = false;
      }
    });
    if (done) {
      setAllRefs(true);
    }
  }, [picRefs.current]);

  let allPics = [...pics]; //isMobile ? [...pics2] : [...pics];
  if (!isMobile) {
    allPics.reverse();
  }
  return (
    <div className={isMobile ? 'App mobile' : 'App'} ref={holder}>
      <div className={!fixed ? 'top' : 'top fixed'}>
        <a href="https://www.halebop.se" target="_blank">
          <img src={logo} className="logo" />
        </a>
        <h1 className="tex">Samtalsrapporten 2023</h1>
      </div>
      {isMobile &&
        allPics.map((item, index) => {
          console.log(index);
          return <PicHolder key={'pic' + index} index={index} item={item} />;
        })}
      {!isMobile &&
        allPics.map((item, index) => {
          return (
            <PicMag
              index={index}
              item={item}
              key={'pic' + index}
              openIndex={selected}
              ref={picRefs.current[index]}
            />
          );
        })}
      {!isMobile && (
        <div
          className="arrow right"
          onClick={() => {
            let ind = parseInt(active.current.getAttribute('index'), 10);
            if (ind === 0) {
              ind = picRefs.current.length;
            }
            gsap.to(active.current, 0.5, {
              scale: 0.3,
              x: active.current.lastX,
              y: active.current.lastY,
              width: active.current.lastW,
              height: active.current.lastH,
              rotation: active.current.lastR,
            });
            openItem(picRefs.current[ind - 1]);
          }}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M5 12H19"
              stroke="#2A2A2B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              data-v-126cff0b=""
              d="M12 5L19 12L12 19"
              stroke="#2A2A2B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
      )}
      {!isMobile && (
        <div
          className="arrow left"
          onClick={() => {
            let ind = parseInt(active.current.getAttribute('index'), 10);
            if (ind === picRefs.current.length - 1) {
              ind = -1;
            }
            gsap.to(active.current, 0.5, {
              scale: 0.3,
              x: active.current.lastX,
              y: active.current.lastY,
              width: active.current.lastW,
              height: active.current.lastH,
              rotation: active.current.lastR,
            });
            openItem(picRefs.current[ind + 1]);
          }}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M5 12H19"
              stroke="#2A2A2B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M12 5L19 12L12 19"
              stroke="#2A2A2B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
      )}
      <a href="samtalsrapporten_2023_halebop.pdf" download className="btn">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M5 12H19"
            stroke="#2A2A2B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M12 5L19 12L12 19"
            stroke="#2A2A2B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
        <p>
          Ladda ner
          <br />
          PDF
        </p>
      </a>
      {!isMobile && (
        <div
          className="bg"
          onClick={() => {
            if (active.current) {
              aPic.current = null;
              gsap.to(active.current, 0.5, {
                scale: 0.3,
                x: active.current.lastX,
                y: active.current.lastY,
                width: active.current.lastW,
                height: active.current.lastH,
                rotation: active.current.lastR,
                onComplete: () => {
                  setSelected(false);
                },
              });
            }
          }}
        ></div>
      )}
      {showCookie && (
        <div className="cookieholder">
          {cookieInfo === 1 && (
            <div className="cookie">
              <h2>Är det okej att vi använder cookies?</h2>
              <p>
                Vi använder cookies för att analysera användningen av vår
                webbplats, samt förbättra vår marknadsföring.
              </p>
              <div className="btnholder">
                <div
                  className={'btnCookie'}
                  onClick={() => {
                    setCookie(1);
                  }}
                >
                  Jag godkänner cookies
                </div>
                <div
                  className={'btnCookie'}
                  onClick={() => {
                    setCookie(2);
                  }}
                >
                  Nej tack
                </div>
                <div
                  className="btnCookie white"
                  onClick={() => {
                    setCookieInfo(2);
                  }}
                >
                  Mer info om våra cookies
                </div>
              </div>
            </div>
          )}
          {cookieInfo === 2 && (
            <div className="cookie extra">
              <div
                className={'btnCookie white'}
                onClick={() => {
                  setCookieInfo(1);
                }}
              >
                {'<- '}Tillbaka
              </div>
              <b className="lihead">Cookies</b>

              <p>
                Webbplatsen använder sig av cookies. Detta innebär att
                information kan komma att lagras i användarnas webbläsare när de
                besöker webbplatsen. Syftet med detta är att webbplatsen ska
                känna igen en användares dator, föra statistik och i sin tur
                förbättra användarupplevelsen.
              </p>
              <br />
              <p>
                En cookie är en liten textfil som gör det möjligt att
                identifiera en användares kommunikationsmedel utan att lagra
                personuppgifter. Det finns två olika typer av cookies,
                tidsbegränsade och sessionscookies. Tidsbegränsade cookies
                sparas under en längre tid och kan tex användas för att visa
                användaren sådant innehåll som tidigare missats. Sessionscookies
                raderas när webbplatsen/webbläsaren stängs ned. Denna typ av
                cookies kan lagra information såsom valt språk och medför att
                användare inte behöver välja om detta på nytt varje gång de
                klickar sig vidare på webbplatsen.
              </p>

              <br />
              <b>Cookieförteckning</b>

              <p>
                Icke nödvändiga cookies (analys och marknadsföring) placeras
                efter att samtycke har inhämtats. Samtliga cookies lagrar alla
                data anonymiserat.
              </p>
              <br />

              <div className="row small ">
                <div className="num">•</div>
                <div className="texline">
                  <p>
                    <b>rconcent</b>
                    <br />
                    www.samtalsrapporten.se
                    <br />
                    En kaka som vi sparar om du godkänt cookies.
                    <br />
                    Livslängd: 9 mån
                    <br />
                    Data delas med: Halebop
                  </p>
                </div>
              </div>
              {/*   <div className="row small ">
                <div className="num">•</div>
                <div className="texline">
                  <p>
                    <b>_dc_gtm_UA-11562942-1</b>
                    <br />
                    www.samtalsrapporten.se
                    <br />
                    En Google Tag Manager-kaka som används för att spåra
                    användarens beteende på webbplatsen.
                  </p>
                </div>
              </div>*/}
              <div className="row small ">
                <div className="num">•</div>
                <div className="texline">
                  <p>
                    <b>_fbp:</b>
                    <br />
                    www.samtalsrapporten.se
                    <br />
                    En Facebook Pixel-cookie används av Facebook för att erbjuda
                    anpassade annonser och spåra ditt besök på vår webbplats.
                    <br />
                    Livslängd: 3 månader
                    <br />
                    Data delas med: Facebook
                  </p>
                </div>
              </div>

              <div className="row small ">
                <div className="num">•</div>
                <div className="texline">
                  <p>
                    <b>_ga</b>
                    <br />
                    www.samtalsrapporten.se
                    <br />
                    Google Analytics-kakor som används för att spåra
                    användaraktivitet och generera statistik om hur besökare
                    använder webbplatsen.
                    <br />
                    Livslängd: 1 år
                    <br />
                    Data delas med: Google
                  </p>
                </div>
              </div>

              <div className="row small ">
                <div className="num">•</div>
                <div className="texline">
                  <p>
                    <b>_ga_PE90MP5KPP</b>
                    <br />
                    www.samtalsrapporten.se
                    <br />
                    Google Analytics-kakor som används för att spåra
                    användaraktivitet och generera statistik om hur besökare
                    använder webbplatsen.
                    <br />
                    Livslängd: 30 dagar
                    <br />
                    Data delas med: Google
                  </p>
                </div>
              </div>

              <div className="row small ">
                <div className="num">•</div>
                <div className="texline">
                  <p>
                    <b>_gid</b>
                    <br />
                    www.samtalsrapporten.se
                    <br />
                    En Google Analytics-kaka som används för att skilja
                    användare åt.
                    <br />
                    Livslängd: 1 vecka
                    <br />
                    Data delas med: Google
                  </p>
                </div>
              </div>

              <b>Hur man tar bort cookies</b>
              <p>
                För att enbart radera cookies från tjänsten, se ovan detaljerad
                förteckning där användaren kan se vilka cookies som används till
                vad, samt vad de heter.
              </p>
              <br />
              <p>
                För att ta bort cookies ska användaren följa instruktionen för
                sin specifika webbläsare. Nedan återfinns länkar till de
                vanligaste webbläsarna. Tyvärr erbjuder inte alla leverantörer
                av webbläsare instruktioner om hur man går tillväga för att
                enbart ta bort specifika cookies om man inte vill rensa hela sin
                cookiehistorik.
              </p>
              <br />
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=sv"
                target="_blank"
              >
                Radera cookies från Google Chrome
              </a>
              <a
                href="https://support.mozilla.org/sv/kb/rensa-kakor-och-webbplatsdata-firefox?redirectlocale=sv&redirectslug=ta-bort-kakor-informationen-webbplatser-lagrat"
                target="_blank"
              >
                Radera cookies från Firefox
              </a>
              <a
                href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                target="_blank"
              >
                Radera cookies från Microsoft Edge
              </a>
              <a
                href="https://help.opera.com/en/latest/web-preferences/#cookies"
                target="_blank"
              >
                Radera cookies från Opera
              </a>
              <a
                href="https://support.apple.com/sv-se/HT201265"
                target="_blank"
              >
                Radera cookies från Safari
              </a>
              <br />
              <p>
                Länkarna ovan går till respektive webbläsares egna
                instruktionssidor, över vilka Halebop inte har någon kontroll –
                Halebop kan därmed inte hållas ansvariga för eventuella
                felaktigheter i dessa.
              </p>
              <div className={'btnholder'} style={{ marginTop: 20 }}>
                <div
                  className={'btnCookie'}
                  onClick={() => {
                    setCookie(1);
                  }}
                >
                  Jag godkänner cookies
                </div>
                <div
                  className={'btnCookie'}
                  onClick={() => {
                    setCookie(2);
                  }}
                >
                  Nej tack
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withCookies(App);
