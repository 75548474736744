// import ReactGA from 'react-ga';
// ReactGA.initialize('GTM-PTXPXGR');
import TagManager from 'react-gtm-module';

const Tracking = {
  page: function(page) {
    const data = {
      dataLayer: {
        event: 'PageView',
        page,
      },
      dataLayerName: 'dataLayer',
    };

    TagManager.dataLayer(data);
  },
  action: function(action, value = false) {
    let data = {
      dataLayer: {
        event: action,
      },
      dataLayerName: 'dataLayer',
    };
    if (value) {
      let layer = { ...data.dataLayer };
      layer = { ...layer, ...value };
      data.dataLayer = layer;
    }

    TagManager.dataLayer(data);
  },
};

export default Tracking;
