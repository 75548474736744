import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useScrollState } from 'scrollmonitor-hooks';
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from 'react-image-magnifiers';

const PicMag = forwardRef((props, ref) => {
  const { item, index, openIndex } = props;

  const scrollState = useScrollState(ref);

  let cn = 0;
  let className = 'picHolder';
  if (scrollState.isInViewport) {
    className = 'picHolder show';
  } else {
    className = 'picHolder';
  }
  if (scrollState.isAboveViewport) {
    className = 'picHolder show';
  }
  let avoidIndex = [20, 19, 18, 17, 15, 14, 12, 10, 9, 6, 2];

  return (
    <>
      <div ref={ref} className="picHolder" index={index}>
        {/*  <p
          style={{
            color: 'red',
            fontWeight: 700,
            position: 'absolute',
            top: 10,
            left: 10,
            padding: 10,
            zIndex: 999,
            backgroundColor: 'white',
          }}
        >
          {index}
        </p>*/}
        {openIndex === index && !avoidIndex.includes(index) && (
          <GlassMagnifier
            imageSrc={item.url}
            magnifierSize={
              index === 8 || index === 7 || index === 3
                ? '80%'
                : index === 4 || index === 0
                ? '35%'
                : '50%'
            }
            largeImageSrc={item.large}
            allowOverflow={true}
          />
        )}
        {(openIndex !== index || avoidIndex.includes(index)) && (
          <img className="picture" src={item.url} />
        )}
      </div>
    </>
  );
});

export default PicMag;
